<template>
  <v-container fluid class="pa-1">
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-card-title v-if="titleEditor">{{ title }}</v-card-title>
        <v-card-title v-if="editor" class="pa-0">
          <v-item-group class="v-btn-toggle v-btn-toggle--dense">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="
                  editor
                    .chain()
                    .focus()
                    .undo()
                    .run()
                  " small class="pa-3" :disabled="!editor.can().undo()" v-bind="attrs" v-on="on">
                  <v-icon small>mdi-undo</v-icon>
                </v-btn>
              </template>
              <span>Desfazer alteração (CTRL+Z)</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="
                  editor
                    .chain()
                    .focus()
                    .redo()
                    .run()
                  " small :disabled="!editor.can().redo()" v-bind="attrs" v-on="on">
                  <v-icon small>mdi-redo</v-icon>
                </v-btn>
              </template>
              <span>Refazer alteração (CTRL+Y) </span>
            </v-tooltip>


          </v-item-group>

          <div class="mx-2"></div>

          <v-btn-toggle v-model="formatacao" multiple dense>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="
                  editor
                    .chain()
                    .focus()
                    .toggleItalic()
                    .run()
                  " small v-bind="attrs" v-on="on">
                  <v-icon small>mdi-format-italic</v-icon>
                </v-btn>
              </template>
              <span>Itálico (CTRL+I) </span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="
                  editor
                    .chain()
                    .focus()
                    .toggleBold()
                    .run()
                  " small v-bind="attrs" v-on="on">
                  <v-icon small>mdi-format-bold</v-icon>
                </v-btn>
              </template>
              <span>Negrito (CTRL+B)</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="
                  editor
                    .chain()
                    .focus()
                    .toggleUnderline()
                    .run()
                  " small v-bind="attrs" v-on="on">
                  <v-icon small>mdi-format-underline</v-icon>
                </v-btn>
              </template>
              <span>Sublinhado (CTRL+U) </span>
            </v-tooltip>

          </v-btn-toggle>

          <div class="mx-2 hidden-xs-only"></div>

          <v-btn-toggle class="hidden-xs-only" v-model="alinhamento" dense>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="
                  editor
                    .chain()
                    .focus()
                    .setTextAlign('left')
                    .run()
                  " small v-bind="attrs" v-on="on">
                  <v-icon small>mdi-format-align-left</v-icon>
                </v-btn>
              </template>
              <span>Alinhar a esquerda (CTRL+SHIFT+L) </span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="
                  editor
                    .chain()
                    .focus()
                    .setTextAlign('center')
                    .run()
                  " small v-bind="attrs" v-on="on">
                  <v-icon small>mdi-format-align-center</v-icon>
                </v-btn>
              </template>
              <span>Centralizar (CTRL+SHIFT+E) </span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="
                  editor
                    .chain()
                    .focus()
                    .setTextAlign('right')
                    .run()
                  " small v-bind="attrs" v-on="on">
                  <v-icon small>mdi-format-align-right</v-icon>
                </v-btn>
              </template>
              <span>Alinhar a direita (CTRL+SHIFT+R) </span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="
                  editor
                    .chain()
                    .focus()
                    .setTextAlign('justify')
                    .run()
                  " small v-bind="attrs" v-on="on">
                  <v-icon small>mdi-format-align-justify</v-icon>
                </v-btn>
              </template>
              <span>Justificar (CTRL+SHIFT+R) </span>
            </v-tooltip>



          </v-btn-toggle>

          <div class="mx-2"></div>

          <v-btn-toggle v-model="listagem" dense>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="
                  editor
                    .chain()
                    .focus()
                    .toggleBulletList()
                    .run()
                  " small v-bind="attrs" v-on="on">
                  <v-icon small>mdi-format-list-bulleted</v-icon>
                </v-btn>
              </template>
              <span>Lista</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="
                  editor
                    .chain()
                    .focus()
                    .toggleOrderedList()
                    .run()
                  " small v-bind="attrs" v-on="on">
                  <v-icon small>mdi-format-list-numbered</v-icon>
                </v-btn>
              </template>
              <span>Lista numerada</span>
            </v-tooltip>
          </v-btn-toggle>

          <div class="mx-2"></div>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn dense tile icon @click="expandLines()" small v-bind="attrs" v-on="on">
                <v-icon small>mdi-arrow-expand-vertical</v-icon>
              </v-btn>
            </template>
            <span>Aumentar espaçamento entre as linhas</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn dense tile icon @click="collapseLines()" small v-bind="attrs" v-on="on">
                <v-icon small>mdi-arrow-collapse-vertical</v-icon>
              </v-btn>
            </template>
            <span>Dininuir espaçamento entre as linhas</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn dense tile icon @click="quebraPagina()" small v-bind="attrs" v-on="on">
                <v-icon small>mdi-format-page-break</v-icon>
              </v-btn>
            </template>
            <span>Quebra de página</span>
          </v-tooltip>

          <div class="mx-2"></div>


          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn dense tile icon @click="
                editor
                  .commands.insertTable({ rows: 3, cols: 3, withHeaderRow: true })
                " small v-bind="attrs" v-on="on">
                <v-icon small>mdi-table</v-icon>
              </v-btn>
            </template>
            <span>Adicionar tabela</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn dense tile icon @click="
                editor.chain().focus().deleteTable().run()" small v-show="editor.can().deleteTable()" v-bind="attrs"
                v-on="on">
                <v-icon small>mdi-table-minus</v-icon>
              </v-btn>
            </template>
            <span>Remover tabela</span>
          </v-tooltip>


          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn dense tile icon @click="
                editor.chain().focus().addRowBefore().run()" small v-show="editor.can().addRowBefore()" v-bind="attrs"
                v-on="on">
                <v-icon small>mdi-table-row-plus-before</v-icon>
              </v-btn>
            </template>
            <span>Adicionar linha antes</span>
          </v-tooltip>


          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn dense tile icon @click="
                editor.chain().focus().addRowAfter().run()" small v-show="editor.can().addRowAfter()" v-bind="attrs"
                v-on="on">
                <v-icon small>mdi-table-row-plus-after</v-icon>
              </v-btn>
            </template>
            <span>Adicionar linha depois</span>
          </v-tooltip>


          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn dense tile icon @click="editor.chain().focus().deleteRow().run()" small
                v-show="editor.can().deleteRow()" v-bind="attrs" v-on="on">
                <v-icon small>mdi-table-row-remove</v-icon>
              </v-btn>
            </template>
            <span>Remover linha</span>
          </v-tooltip>



          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn dense tile icon @click="editor.chain().focus().addColumnBefore().run()" small
                v-show="editor.can().addColumnBefore()" v-bind="attrs" v-on="on">
                <v-icon small>mdi-table-column-plus-before</v-icon>
              </v-btn>
            </template>
            <span>Adicionar coluna antes</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn dense tile icon @click="editor.chain().focus().addColumnAfter().run()" small
                v-show="editor.can().addColumnAfter()" v-bind="attrs" v-on="on">
                <v-icon small>mdi-table-column-plus-after</v-icon>
              </v-btn>
            </template>
            <span>Adicionar coluna depois</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn dense tile icon @click="editor.chain().focus().deleteColumn().run()" small
                v-show="editor.can().deleteColumn()" v-bind="attrs" v-on="on">
                <v-icon small>mdi-table-column-remove</v-icon>
              </v-btn>
            </template>
            <span>Remover coluna</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn dense tile icon @click="editor.chain().focus().toggleHeaderRow().run()" small
                v-show="editor.can().toggleHeaderRow()" v-bind="attrs" v-on="on">
                <v-icon small>mdi-table-row</v-icon>
              </v-btn>
            </template>
            <span>Destaque linha </span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn dense tile icon @click="editor.chain().focus().toggleHeaderColumn().run()" small
                v-show="editor.can().toggleHeaderColumn()" v-bind="attrs" v-on="on">
                <v-icon small>mdi-table-column</v-icon>
              </v-btn>
            </template>
            <span>Destaque coluna</span>
          </v-tooltip>
        </v-card-title>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="px-0 pt-1">
        <editor-content :editor="editor" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import HardBreak from '@tiptap/extension-hard-break'
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'

// import CreateSelectExtension from "./CreateSelectExtension.js";

export default {
  name: "simple-editor",
  components: {
    EditorContent    
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    titleEditor: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      editor: null,
    };
  },

  computed: {
    isBold() {
      return this.editor && this.editor.isActive("bold");
    },
    isItalic() {
      return this.editor && this.editor.isActive("italic");
    },
    isUnderline() {
      return this.editor && this.editor.isActive("underline");
    },
    isLeft() {
      return this.editor && this.editor.isActive({ textAlign: "left" });
    },
    isCenter() {
      return this.editor && this.editor.isActive({ textAlign: "center" });
    },
    isRight() {
      return this.editor && this.editor.isActive({ textAlign: "right" });
    },
    isJustify() {
      return this.editor && this.editor.isActive({ textAlign: "justify" });
    },
    isBulletList() {
      return this.editor && this.editor.isActive("bulletList");
    },
    isOrderedList() {
      return this.editor && this.editor.isActive("orderedList");
    },
    formatacao: {
      get() {
        let formatacao = [];
        this.isItalic ? formatacao.push(0) : null;
        this.isBold ? formatacao.push(1) : null;
        this.isUnderline ? formatacao.push(2) : null;
        return formatacao;
      },
      set() { }
    },
    listagem: {
      get() {
        let listagem = null;
        if (this.isBulletList) {
          listagem = 0;
        } else if (this.isOrderedList) {
          listagem = 1;
        }
        return listagem;
      },
      set() { }
    },
    alinhamento: {
      get() {
        let alinhamento = null;

        if (this.isLeft) {
          alinhamento = 0;
        } else if (this.isCenter) {
          alinhamento = 1;
        } else if (this.isRight) {
          alinhamento = 2;
        } else if (this.isJustify) {
          alinhamento = 3;
        }
        return alinhamento;
      },
      set() { }
    }
  },
  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value;

      // JSON
      // const isSame = this.editor.getJSON().toString() === value.toString()

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(value, false);
    }
  },

  async mounted() {
    let extensions = [
      StarterKit.configure({
        // Disable an included extension
        hardBreak: false,

        // Configure an included extension
        // heading: {
        //   levels: [1, 2],
        // },       
        horizontalRule: {
          HTMLAttributes: {
            class: 'quebra-pagina-class',
          },
        }
      }),
      HardBreak.configure({
        keepMarks: true,
        keepAttributes: false,
      }),
      Underline,
      TextAlign.configure({
        types: ["paragraph"]
      }),
      Table.configure({
        resizable: true,
      }),
      TableCell,
      TableHeader,
      TableRow,
    ]


    this.editor = new Editor({
      extensions: extensions,
      content: this.value,
      injectCSS: false,
      // autofocus: true,
      editable: true,
      onUpdate: () => {
        // HTML
        this.$emit("input", this.editor.getHTML());
        // console.log("onUpdate",this.editor.getHTML());
        // JSON
        // this.$emit('update:value', this.editor.getJSON())
      }
    });
  },

  beforeUnmount() {
    this.editor.destroy();
  },
  methods: {
    quebraPagina() {
      this.editor.commands.setHorizontalRule()
    },
    getSelectedText(){
      const { view, state } = this.editor
      const { from, to } = view.state.selection
      const text = state.doc.textBetween(from, to, ' ')
      return text;
    },
    capitalizeFirstLetter(text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
    expandLines() {
      const lineP = {
        "type": "paragraph",
        "attrs": {
          "textAlign": "left"
        }
      }
      const content = this.editor.getJSON().content;
      let newContent = []
      for (let index = 0; index < content.length; index++) {
        const contentItem = content[index];
        newContent.push(contentItem);
        if (contentItem.content)
          newContent.push(lineP);
      }
      this.editor.commands.setContent(newContent, true)
    },
    collapseLines() {
      const content = this.editor.getJSON().content;
      let newContent = []
      for (let index = 0; index < content.length; index++) {
        const contentItem = content[index];
        if (index > 0) {
          const contentLastItem = content[index - 1];
          if (contentLastItem.content && !contentItem.content) {
            //newContent.push(contentItem);            
          } else {
            newContent.push(contentItem);
          }
        } else {
          newContent.push(contentItem);
        }
      }
      this.editor.commands.setContent(newContent, true)

    }
  }
};
</script>

<style lang="scss">
.theme--light .ProseMirror {
  border: thin solid rgba(0, 0, 0, 0.12);
  color: black;
}

.theme--light .ProseMirror:focus {
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.theme--dark .ProseMirror {
  border: thin solid rgba(255, 255, 255, 0.12);
  color: white;
}

.theme--dark .ProseMirror:focus {
  outline: none;
  border: 1px solid rgba(255, 255, 255, 0.3);
}


.ProseMirror {
  min-height: 200px;
  padding: 10px;
  border-radius: 5px;

  p {
    padding: 0;
    margin: 0;
  }

  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #ced4da;      
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      >* {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
      color: #000000;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }
  }
}

.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

.quebra-pagina-class {
  margin-top: 10px;
  border-top: 1px dotted #000;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-1",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[(_vm.titleEditor)?_c('v-card-title',[_vm._v(_vm._s(_vm.title))]):_vm._e(),(_vm.editor)?_c('v-card-title',{staticClass:"pa-0"},[_c('v-item-group',{staticClass:"v-btn-toggle v-btn-toggle--dense"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-3",attrs:{"icon":"","small":"","disabled":!_vm.editor.can().undo()},on:{"click":function($event){_vm.editor
                  .chain()
                  .focus()
                  .undo()
                  .run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-undo")])],1)]}}],null,false,4272482187)},[_c('span',[_vm._v("Desfazer alteração (CTRL+Z)")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","disabled":!_vm.editor.can().redo()},on:{"click":function($event){_vm.editor
                  .chain()
                  .focus()
                  .redo()
                  .run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-redo")])],1)]}}],null,false,3177521864)},[_c('span',[_vm._v("Refazer alteração (CTRL+Y) ")])])],1),_c('div',{staticClass:"mx-2"}),_c('v-btn-toggle',{attrs:{"multiple":"","dense":""},model:{value:(_vm.formatacao),callback:function ($$v) {_vm.formatacao=$$v},expression:"formatacao"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor
                  .chain()
                  .focus()
                  .toggleItalic()
                  .run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-format-italic")])],1)]}}],null,false,1857149314)},[_c('span',[_vm._v("Itálico (CTRL+I) ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor
                  .chain()
                  .focus()
                  .toggleBold()
                  .run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-format-bold")])],1)]}}],null,false,4194274754)},[_c('span',[_vm._v("Negrito (CTRL+B)")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor
                  .chain()
                  .focus()
                  .toggleUnderline()
                  .run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-format-underline")])],1)]}}],null,false,941737858)},[_c('span',[_vm._v("Sublinhado (CTRL+U) ")])])],1),_c('div',{staticClass:"mx-2 hidden-xs-only"}),_c('v-btn-toggle',{staticClass:"hidden-xs-only",attrs:{"dense":""},model:{value:(_vm.alinhamento),callback:function ($$v) {_vm.alinhamento=$$v},expression:"alinhamento"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor
                  .chain()
                  .focus()
                  .setTextAlign('left')
                  .run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-format-align-left")])],1)]}}],null,false,2129325826)},[_c('span',[_vm._v("Alinhar a esquerda (CTRL+SHIFT+L) ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor
                  .chain()
                  .focus()
                  .setTextAlign('center')
                  .run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-format-align-center")])],1)]}}],null,false,353650434)},[_c('span',[_vm._v("Centralizar (CTRL+SHIFT+E) ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor
                  .chain()
                  .focus()
                  .setTextAlign('right')
                  .run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-format-align-right")])],1)]}}],null,false,2147947746)},[_c('span',[_vm._v("Alinhar a direita (CTRL+SHIFT+R) ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor
                  .chain()
                  .focus()
                  .setTextAlign('justify')
                  .run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-format-align-justify")])],1)]}}],null,false,2144944546)},[_c('span',[_vm._v("Justificar (CTRL+SHIFT+R) ")])])],1),_c('div',{staticClass:"mx-2"}),_c('v-btn-toggle',{attrs:{"dense":""},model:{value:(_vm.listagem),callback:function ($$v) {_vm.listagem=$$v},expression:"listagem"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor
                  .chain()
                  .focus()
                  .toggleBulletList()
                  .run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-format-list-bulleted")])],1)]}}],null,false,3603415982)},[_c('span',[_vm._v("Lista")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor
                  .chain()
                  .focus()
                  .toggleOrderedList()
                  .run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-format-list-numbered")])],1)]}}],null,false,2374136226)},[_c('span',[_vm._v("Lista numerada")])])],1),_c('div',{staticClass:"mx-2"}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dense":"","tile":"","icon":"","small":""},on:{"click":function($event){return _vm.expandLines()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-expand-vertical")])],1)]}}],null,false,133244711)},[_c('span',[_vm._v("Aumentar espaçamento entre as linhas")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dense":"","tile":"","icon":"","small":""},on:{"click":function($event){return _vm.collapseLines()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-collapse-vertical")])],1)]}}],null,false,2973054855)},[_c('span',[_vm._v("Dininuir espaçamento entre as linhas")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dense":"","tile":"","icon":"","small":""},on:{"click":function($event){return _vm.quebraPagina()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-format-page-break")])],1)]}}],null,false,3895087486)},[_c('span',[_vm._v("Quebra de página")])]),_c('div',{staticClass:"mx-2"}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dense":"","tile":"","icon":"","small":""},on:{"click":function($event){_vm.editor
                .commands.insertTable({ rows: 3, cols: 3, withHeaderRow: true })}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-table")])],1)]}}],null,false,3499356964)},[_c('span',[_vm._v("Adicionar tabela")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.editor.can().deleteTable()),expression:"editor.can().deleteTable()"}],attrs:{"dense":"","tile":"","icon":"","small":""},on:{"click":function($event){_vm.editor.chain().focus().deleteTable().run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-table-minus")])],1)]}}],null,false,3287216498)},[_c('span',[_vm._v("Remover tabela")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.editor.can().addRowBefore()),expression:"editor.can().addRowBefore()"}],attrs:{"dense":"","tile":"","icon":"","small":""},on:{"click":function($event){_vm.editor.chain().focus().addRowBefore().run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-table-row-plus-before")])],1)]}}],null,false,3071582306)},[_c('span',[_vm._v("Adicionar linha antes")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.editor.can().addRowAfter()),expression:"editor.can().addRowAfter()"}],attrs:{"dense":"","tile":"","icon":"","small":""},on:{"click":function($event){_vm.editor.chain().focus().addRowAfter().run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-table-row-plus-after")])],1)]}}],null,false,3319094882)},[_c('span',[_vm._v("Adicionar linha depois")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.editor.can().deleteRow()),expression:"editor.can().deleteRow()"}],attrs:{"dense":"","tile":"","icon":"","small":""},on:{"click":function($event){_vm.editor.chain().focus().deleteRow().run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-table-row-remove")])],1)]}}],null,false,661059563)},[_c('span',[_vm._v("Remover linha")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.editor.can().addColumnBefore()),expression:"editor.can().addColumnBefore()"}],attrs:{"dense":"","tile":"","icon":"","small":""},on:{"click":function($event){_vm.editor.chain().focus().addColumnBefore().run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-table-column-plus-before")])],1)]}}],null,false,3247155234)},[_c('span',[_vm._v("Adicionar coluna antes")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.editor.can().addColumnAfter()),expression:"editor.can().addColumnAfter()"}],attrs:{"dense":"","tile":"","icon":"","small":""},on:{"click":function($event){_vm.editor.chain().focus().addColumnAfter().run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-table-column-plus-after")])],1)]}}],null,false,2562383522)},[_c('span',[_vm._v("Adicionar coluna depois")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.editor.can().deleteColumn()),expression:"editor.can().deleteColumn()"}],attrs:{"dense":"","tile":"","icon":"","small":""},on:{"click":function($event){_vm.editor.chain().focus().deleteColumn().run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-table-column-remove")])],1)]}}],null,false,797262699)},[_c('span',[_vm._v("Remover coluna")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.editor.can().toggleHeaderRow()),expression:"editor.can().toggleHeaderRow()"}],attrs:{"dense":"","tile":"","icon":"","small":""},on:{"click":function($event){_vm.editor.chain().focus().toggleHeaderRow().run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-table-row")])],1)]}}],null,false,2866465684)},[_c('span',[_vm._v("Destaque linha ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.editor.can().toggleHeaderColumn()),expression:"editor.can().toggleHeaderColumn()"}],attrs:{"dense":"","tile":"","icon":"","small":""},on:{"click":function($event){_vm.editor.chain().focus().toggleHeaderColumn().run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-table-column")])],1)]}}],null,false,2020816724)},[_c('span',[_vm._v("Destaque coluna")])])],1):_vm._e()],1)],1),_c('v-row',[_c('v-col',{staticClass:"px-0 pt-1",attrs:{"cols":"12"}},[_c('editor-content',{attrs:{"editor":_vm.editor}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }